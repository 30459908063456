<template>
  <section id="hero">
    <template>
      <v-carousel
        v-model="mCarousel"
        :height="minHeight"
        continuous
        cycle
        interval="40000"
      >
        <v-carousel-item :src="require('@/assets/bgs/bg1.jpg')">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-btn
              class="btn-title"
              outlined
            >
              RECURSOS HUMANOS
            </v-btn>
          </v-row>
        </v-carousel-item>
        <v-carousel-item :src="require('@/assets/bgs/bg2.jpg')">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-btn
              class="btn-title"
              outlined
            >
              INCORPORAÇÃO IMOBILIÁRIA
            </v-btn>
          </v-row>
        </v-carousel-item>
        <v-carousel-item :src="require('@/assets/bgs/bg3.jpg')">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-btn
              class="btn-title"
              outlined
            >
              ADMINISTRADORA DE CONDOMÍNIOS
            </v-btn>
          </v-row>
        </v-carousel-item>
        <v-carousel-item :src="require('@/assets/bgs/bg4.jpg')">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-btn
              class="btn-title"
              outlined
            >
              ASSESSORIA JURÍDICA
            </v-btn>
          </v-row>
        </v-carousel-item>
        <v-carousel-item :src="require('@/assets/bgs/bg5.jpg')">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-btn
              class="btn-title"
              outlined
            >
              ASSESSORIA CONTÁBIL
            </v-btn>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </template>
    <!--
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/light.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="WE LOVE MAKING THINGS SIMPLE AND AMAZING"
          />

          <base-heading
            size="text-h2"
            title="WELCOME TO ZERO"
            weight="medium"
          />

          <base-body>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae nobis neque aspernatur in quas iure id aliquid, esse debitis, quibusdam mollitia! Quia ea expedita magnam totam, earum omnis et perspiciatis?
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn>
              Discover More
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              Get Started Now
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
    -->
  </section>
</template>
<style>
  @media only screen and (min-width: 801px) {
    .btn-title {
      font-size: 3rem !important;
      min-height: 55px;
      font-weight: 500;
      text-shadow: 0px 0px 5px black;
    }
  }
  @media only screen and (max-width: 800px) {
    .btn-title {
      font-size: 1.5rem !important;
      min-height: 55px;
      font-weight: 500;
      text-shadow: 0px 0px 5px black;
    }
  }
  @media only screen and (max-width: 600px) {
    .btn-title {
      font-size: 1rem !important;
      min-height: 55px;
      font-weight: 500;
      text-shadow: 0px 0px 5px black;
    }
  }
</style>
<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        windowHeight: window.innerHeight,
        mCarousel: null,
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '70vh' : '100vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
